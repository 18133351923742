<template>
    <div class="cipher flex-center">
        <div class="cipher-box">
            <div class="cipher-title">{{password_title}}</div>
            <el-input v-model="password" placeholder="请输入密码 "></el-input>
            <div class="login-btn" @click="passwordLogin">登录</div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex"; //引入vuex
export default {
    data () {
        return {
            password: '',
            password_title: ''
        }
    },
    computed:{
        ...mapState(["themecolors"])
    },
    async created() {
        const res = await this.$store.dispatch('get_password_exhibit', {
            site_id: this.$route.query.site_id
        })
        if(res.data.code == 200) {
            this.password_title = res.data.data.title
        }
    },
    methods: {
        async passwordLogin() {
            if(this.password){
                const res = await this.$store.dispatch('password_verify', {
                    site_id: this.$route.query.site_id,
                    password: this.password
                })
                if(res.data.code == 200) {
                    this.$
                }
                else{
                    this.$toast(res.data.message);
                }
            } else {
                this.$toast('请输入密码！');
            }
        }
    }
}
</script>

<style lang="less" scoped>
@Color:var(--themecolor);
.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cipher{
    .cipher-box{
        width: 300px;
        height: 300px;
        box-shadow: 0 5px 12px 1px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .cipher-title{
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 16px;
            font-weight: 700;
            // height: 50px;
            line-height: 25px;
        }
        .el-input{
            margin: 26px 0 34px;
        }
        .login-btn{
            width: 100%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            // background-color: @Color;
            border-radius: 20px;
            background-color: #00ccb0;
        }
    }
}
</style>